export default {
    wangEditor: {
        插入: "插入",
        默认: "默認",
        创建: "創建",
        修改: "修改",
        如: "如",
        请输入正文: "請輸入詳情",
        menus: {
            title: {
                标题: "標題",
                加粗: "加粗",
                字号: "字號",
                字体: "字體",
                斜体: "斜體",
                下划线: "下劃綫",
                删除线: "刪除綫",
                缩进: "縮進",
                行高: "行高",
                文字颜色: "文字顔色",
                背景色: "背景色",
                链接: "鏈接",
                序列: "序列",
                对齐: "對齊",
                引用: "引用",
                表情: "表情",
                图片: "圖片",
                视频: "視頻",
                表格: "表格",
                代码: "代碼",
                分割线: "分割綫",
                恢复: "恢復",
                撤销: "撤銷",
                全屏: "全屏",
                代办事项: "待辦事項",
            },
            dropListMenu: {
                设置标题: "設置標題",
                背景颜色: "背景顔色",
                文字颜色: "文字顔色",
                设置字号: "設置字號",
                设置字体: "設置字體",
                设置缩进: "設置縮進",
                对齐方式: "對齊方式",
                设置行高: "設置行高",
                序列: "序列",
                head: {
                    正文: "正文",
                },
                indent: {
                    增加缩进: "增加縮進",
                    减少缩进: "減少縮進",
                },
                font: {
                    黑体: "黑體"
                },
                justify: {
                    靠左: "靠左",
                    居中: "居中",
                    靠右: "靠右",
                    两端: "兩端"
                },
                list: {
                    无序列表: "無序列表",
                    有序列表: "有序列表",
                },
            },
            panelMenus: {
                emoticon: {
                    默认: "默認",
                    新浪: "新浪",
                    emoji: "emoji",
                    手势: "手勢",
                },
                image: {
                    图片链接: "圖片鏈接",
                    上传图片: "上傳圖片",
                    网络图片: "網絡圖片",
                },
                link: {
                    链接: "鏈接",
                    链接文字: "鏈接文字",
                    删除链接: "刪除鏈接",
                    查看链接: "查看鏈接",
                },
                video: {
                    插入视频: "插入視頻",
                },
                table: {
                    行: "行",
                    列: "列",
                    的: " ",
                    表格: "表格",
                    添加行: "添加行",
                    删除行: "刪除行",
                    添加列: "添加列",
                    删除列: "刪除列",
                    设置表头: "設置表頭",
                    取消表头: "取消表頭",
                    插入表格: "插入表格",
                    删除表格: "刪除表格",
                },
                code: {
                    删除代码: "刪除代碼",
                    修改代码: "修改代碼",
                    插入代码: "插入代碼",
                },
            },
        },
        validate: {
            张图片: "張圖片",
            大于: "大於",
            图片链接: "圖片鏈接",
            不是图片: "不是圖片",
            返回结果: "返回結果",
            上传图片超时: "上傳圖片超時",
            上传图片错误: "上傳圖片錯誤",
            上传图片失败: "上傳圖片失敗",
            插入图片错误: "插入圖片錯誤",
            一次最多上传: "一次最多上傳",
            下载链接失败: "下載鏈接失敗",
            图片验证未通过: "圖片未通過驗證",
            服务器返回状态: "服務器返回狀態",
            上传图片返回结果错误: "上傳圖片返回結果錯誤",
            请替换为支持的图片类型: "請替換為支持的圖片類型",
            您插入的网络图片无法识别: "您插入的網絡圖片無法識別",
            您刚才插入的图片链接未通过编辑器校验: "您剛才插入的圖片鏈接未通過編輯器校驗",
        },
    },
};